//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import func from "@/utils/decoded";
import {fetchFilter} from "@/api/master";
import {fetchAssignTaskList} from "@/api/assignTask";
import {Message} from "element-ui";
import moment from "moment/moment";

const fields = [
	{key: 'contract_id', label: 'เลขที่สัญญา', _style: 'min-width:100px'},
	{key: 'name_customer', label: 'ชื่อ-สกุล', _style: 'min-width:150px;'},
	{key: 'balance', label: 'ยอดเงินคงเหลือ', _style: 'min-width:100px; text-align: right;'},
	{key: 'status', label: 'สถานะ', _style: 'min-width:100px;'},
	{key: 'receiveDate', label: 'วันที่รับเล่มทะเบียน', _style: 'min-width:100px;'},
	{key: 'processDay', label: 'เวลาดำเนินการ(วัน)', _style: 'min-width:50px; text-align: right;'},
	{
		key: 'show_details',
		label: 'รายละเอียด',
		_style: 'min-width:50px; text-align: center;',
		sorter: false,
		filter: false
	}
]

export default {
	name: 'AssignTask',
	data() {
		return {
			options: ['ทั้งหมด', 'รอดำเนินการ 0-15 วัน', 'รอดำเนินการ 16-30 วัน', 'รอดำเนินการ 31-45 วัน', 'รอดำเนินการมากกว่า 45 วัน', 'เรียบร้อย', 'ไม่เรียบร้อย'],
			items: [],
			fields,
			searchParams: {
				keyword: '',
				startDate: '',
				endDate: '',
				filter: 0
			}
		}
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
		const check = func.checkMenu(this.$route.path)
		if (check && check.length === 0) {
			this.$router.push({path: '/404'})
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				if (route.query && route.query.page) {
					this.activePage = Number(route.query.page)
				}
			}
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			this.searchParams.endDate = moment().format('YYYY-MM-DD')
			this.searchParams.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')
			await new Promise((resolve, reject) => {
				const param = {
					'stepId': 31
				}
				fetchFilter(param).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.options = data
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
			await this.getContractData()
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		async getContractData() {
			await fetchAssignTaskList(this.searchParams).then(response => {
				this.items = []
				const check = response.header
				const data = response.body
				if (check.error === 'N') {
					for (let item of data) {
						if (item.cur_status_id === 1)
							item.status = 'รับเล่มทะเบียน[ไม่เรียบร้อย]'
						else if (item.cur_status_id === 2)
							if (item.transfer_user_id && item.transfer_user_id > 0)
								item.status = 'มอบหมายงาน[เรียบร้อย]'
						else
							item.status = 'รับเล่มทะเบียน[เรียบร้อย]'
						else
							item.status = 'รอมอบหมายงาน'
						this.items.push(item)
					}
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		getBadge(statusId, transferUserId) {
			switch (statusId) {
				case 1:
					return 'danger'
				case 2:
					if (transferUserId && transferUserId > 0)
						return 'info'
					else
						return 'success'
				case null:
					return 'warning'
				default:
					return 'primary'
			}
		},
		rowClickDetail(item, index) {
			this.$router.push({path: `/step3/AssignTaskDetail/${item.contract_id}`})
		},
		pageChange(val) {
			console.log(val);
			this.$router.push({query: {page: val}})
		}
	}
}
